import { Page } from '@app/core/interfaces/page'
import { LoggerService } from '@app/core/services/logger.service'
import { PageService } from '@app/core/services/page.service'
import { of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { inject } from '@angular/core'
import { ResolveFn, Router } from '@angular/router'

const pageResolver = (slug: string): ResolveFn<Page | null> => {
  return () => {
    const pageService = inject(PageService)
    const router = inject(Router)
    const loggerService = inject(LoggerService)

    return pageService.get(slug).pipe(
      tap((v) => loggerService.log(`pageResolver, ${slug}`, v)),
      catchError(() => {
        router.navigateByUrl('/')

        return of(null)
      }),
    )
  }
}

export const newsResolver = pageResolver('news')

export const homeResolver = pageResolver('home')

export const servicePointsResolver = pageResolver('service_points')

export const serviceCheckCertificateResolver = pageResolver('service_check_certificate')

export const serviceSignatureCertificateResolver = pageResolver('service_signature_certificate')

export const serviceRefreshCertificateResolver = pageResolver('service_refresh_certificate')

export const serviceBuyStorageResolver = pageResolver('service_buy_storage')

export const becomePartnersResolver = pageResolver('become_partner')
