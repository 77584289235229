import { ResponseInterface } from '@app/core/interfaces/response'
import { NewsService } from '@app/features/news/news.service'
import { first, map } from 'rxjs'
import { NewsCard } from 'src/app/features/news/shared/types'

import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router'

export const newsListResolver: ResolveFn<ResponseInterface<NewsCard>> = (route: ActivatedRouteSnapshot) => {
  const newsService = inject(NewsService)
  const take = route.data['take'] ?? 3

  return newsService.getNews({ take, skip: 0 }).pipe(
    first(),
    map((resp) => resp),
  )
}
